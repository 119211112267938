.App {

}

header {
    font-size: 64px;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

#logo-container {
    display: flex;
    justify-content: center;
}